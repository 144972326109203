export interface Profile {
	address: string
	rank: string
	symbol: string
	name: string
	img: string,
	tokens: string,
	holders: string,
	twitter: string,
	discord: string,
	website: string,
	floorPrice: string,
	isVerified: boolean,
	createdAt: string,
}

export const PER_PAGE = 20


const options = {
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
	}
}

// process.env.NODE_ENV === 'development'
// 

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://nft-api.k3l.io/api'
// const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000/api'

export async function getStrategyData(page: number, search: string, strategyId: string) {
	const offset = Number((Math.max(page - 1, 0)) * PER_PAGE)
	const limit = Number(PER_PAGE)
	const results = await fetch(`${backendUrl}/strategy/${strategyId}/?offset=${offset}&limt=${limit}&search=${search}`).then(r => r.json())

	return results as Profile[]
}

export async function getStrategyCount(search: string, strategyId: string) {
	const results = await fetch(`${backendUrl}/strategy/${strategyId}/count?search=${search}`).then(r => r.json())

	// @ts-ignore
	return results.count
}

export async function getStrategies() {
	const results = await fetch(`${backendUrl}/strategy/`).then(r => r.json())
	// @ts-ignore
	return results
}

export async function getMeta(addresses: string[]) {
	console.log('getMeta', { addresses })
	const body = JSON.stringify({ addresses })
	const results = await fetch(`${backendUrl}/meta/`, { ...options, body }).then(r => r.json())
	// @ts-ignore
	return results
}

export async function getPersonalByAddress(address: string) {
	const a = address.toLowerCase()
	const personalRecommendationBackendUrl = 'https://nft-recommend.k3l.io/recommendations?wallet='
	const results = await fetch(`${personalRecommendationBackendUrl}${a}`,{
	
	}).then(r => r.json()).then(r => {
		if(Array.isArray(r)) {
			return r
		}

		return []
	})
	const meta = await getMeta(results.map((a: any) => a.address.toLowerCase()))

	const mergedResults = results.map((e: any) => {
		const m = meta.find((a: any) => a.address.toLocaleLowerCase() === e.address.toLocaleLowerCase()) || {}
		return {
			...m,
			...e,
		}
	})

	return mergedResults
}


import { useEffect, useState, useCallback } from 'react'
import {
	getStrategyData,
	// PER_PAGE,
	getPersonalByAddress,
	Profile
} from '../api/api'

import Pagination from './Pagination'
import { explorerNFTURL, formatPrice, setWindowParam, getWindowParam, tweet } from '../utils'
import { normalizeLinks } from '../api/meta'
import { Search } from './Search'
import { Tooltip } from './Tooltip'
import { Table } from './Table'
import { ethers } from 'ethers'


const PER_PAGE = 1000


export const loader = async (page: number, search: string) => {
	const results = await getPersonalByAddress(search)

	const data = {
		results: results || [],
		page: 1,
		count: results.length
	}

	return data
}

const getInitialPage = () => {
	return +getWindowParam('page') || 1
}


export default function PersonalList(props: any) {
	const [data, setData] = useState({
		results: [] as Profile[],
		page: getInitialPage(),
		count: 0
	})

	const [page, setPage] = useState(getInitialPage())
	const [search, setSearch] = useState(getWindowParam('search') || '')

	const onSearch = useCallback((s: string) => {
		setWindowParam('page', '1')
		setWindowParam('search', s)
		setSearch(s)
		setPage(1)
	}, [])


	useEffect(() => {
		if (!ethers.isAddress(search)) {
			setData({
				results: [],
				page: 1,
				count: 0
			})
			return
		}

		const run = async () => {
			const d = await loader(page, search)
			setData(d)
		}

		run()
	}, [page, search])




	return (
		<main>

			<div className="logo-container">
				<a href="https://karma3labs.com/" target="_blank">
					<img
						width="180px"
						className="logo"
						src="/logo.svg"
						draggable="false"
						alt="Karma3Labs Logo"
					/>
				</a>
			</div>

			<div className="container">
				<header>
					<div className="title">
						<h1>Personal Recommendations</h1>
						<p>
							<small>
								Open, Verifiable and Social attestation based NFT rankings&nbsp;
								<a style={{ borderBottom: '1px solid white' }}
									target="_blank" href="https://karma3-labs.gitbook.io/karma3labs/eigentrust/intuition">powered by EigenTrust</a>.&nbsp;
								<a style={{ borderBottom: '1px solid white' }} href="https://mirror.xyz/0x6BE454113A58861A09Fc2A57940a344357B3859a/6jyYtlnINF7E389vM4dJ0L0nWSWjxlY6PD1aUz1cmZA" target="_blank">&nbsp;Learn More.</a>
							</small>
						</p>

					</div>

					<div className="strategies">
						<Tooltip text={'This strategy starts with top 20 blue chip NFTs, assuming the purchasers behind these 20 are “smart or reputable”. It computes NFT rankings for all NFTs based on future purchases by owners of reputable NFTs.'}>
							<button
								style={{ width: 400, whiteSpace: 'break-spaces' }}
								key={'soon'}
								className={'btn'}
								onClick={() => {
									window.location.pathname = '/'
								}}
								type="submit">
								Based on “Reputable/OG wallets” who bought Top 20 NFT Collections
							</button>

						</Tooltip>

						<Tooltip text={''}>
							<button
								style={{ width: 400, whiteSpace: 'break-spaces' }}
								key={'soon'}
								className={'btn active'}
								onClick={() => {
									window.location.pathname = '/personal'
								}}
								type="submit">
								Recommendations "For You" based on consensus among collectors like you
							</button>

						</Tooltip>

					</div>

					<div className="search">
						<input
							type="text"
							name="handle"
							placeholder='Enter Address'
							value={search}
							onChange={(e) => {
								const v = e.target.value.toLowerCase()
								onSearch(v)
							}}
						/>

						{(
							<button
								className="btn"
								type="button"
								onClick={() => {
									onSearch('')
								}}
							>
								Clear
							</button>
						)}

					</div>


					<a style={{ textDecoration: 'underline' }} href="https://twitter.com/karma3labs" target="_blank">
						<button className="twitter"
							style={{ marginTop: 30 }}
						/*onClick={() => {}/*tweet('Check out the Open NFT Rankings here:')*/
						>
							<i></i>&nbsp;Twitter
						</button>
					</a>

				</header>
				<div className="scroll" style={{ marginTop: 10 }}>
					<div className="profiles-container">
						<div>
							<Table data={data.results} />

						</div>
						<Pagination
							numberOfPages={Math.ceil(data.count / PER_PAGE)}
							currentPage={data.page}
							cb={p => setPage(p)}
						/>
					</div>
				</div>
			</div>
		</main>
	)
}

export function ErrorBoundary({ error }: { error: Error }) {
	return (
		<main>
			<div className="container">
				<h1>Error</h1>
				<p>{error.message}</p>
				<p>The stack trace is:</p>
				<pre>{error.stack}</pre>
			</div>
		</main>
	)
}

export const tradeCountChart = [
    {
      "rank": 20,
      "Collection": "Lil Nouns",
      "nft_contract_address": "0x4b10701bfd7bfedc47d50562b76b436fbb5bdb3b",
      "total_trade_count": 359
    },
    {
      "rank": 21,
      "Collection": "1984 Worldwide",
      "nft_contract_address": "0x662081d1df478ba4aceda637dcf1ef762e7a3a6e",
      "total_trade_count": 3
    },
    {
      "rank": 22,
      "Collection": "Webaverse Genesis Pass",
      "nft_contract_address": "0x543d43f390b7d681513045e8a85707438c463d80",
      "total_trade_count": 24
    },
    {
      "rank": 23,
      "Collection": "loomlocknft",
      "nft_contract_address": "0x1d20a51f088492a0f1c57f047a9e30c9ab5c07ea",
      "total_trade_count": 26
    },
    {
      "rank": 25,
      "Collection": "Anata",
      "nft_contract_address": "0xf729f878f95548bc7f14b127c96089cf121505f8",
      "total_trade_count": 25
    },
    {
      "rank": 26,
      "Collection": "FEWOCiOUS x RTFKT",
      "nft_contract_address": "0x2250d7c238392f4b575bb26c672afe45f0adcb75",
      "total_trade_count": 5
    },
    {
      "rank": 27,
      "Collection": "The Ninja Hideout",
      "nft_contract_address": "0x97e41d5ce9c8cb1f83947ef82a86e345aed673f3",
      "total_trade_count": 2
    },
    {
      "rank": 28,
      "Collection": "Corruptions",
      "nft_contract_address": "0x5bdf397bb2912859dbd8011f320a222f79a28d2e",
      "total_trade_count": 7
    },
    {
      "rank": 29,
      "Collection": "Jadu AVA",
      "nft_contract_address": "0x86fc6f6c6702cef7d3bae87ef41256715416db71",
      "total_trade_count": 7
    },
    {
      "rank": 30,
      "Collection": "QQL Mint Pass",
      "nft_contract_address": "0xc73b17179bf0c59cd5860bb25247d1d1092c1088",
      "total_trade_count": 13
    },
    {
      "rank": 31,
      "Collection": "rektguy",
      "nft_contract_address": "0xb852c6b5892256c264cc2c888ea462189154d8d7",
      "total_trade_count": 491
    },
    {
      "rank": 32,
      "Collection": "MERGE VV",
      "nft_contract_address": "0xbd8451d2d5fb88469a764b05c1e0b623c5106145",
      "total_trade_count": 25
    },
    {
      "rank": 33,
      "Collection": "CamelsNFTio",
      "nft_contract_address": "0x77e2545d1d63856e22ce82e3d6f2a3b2077232bf",
      "total_trade_count": 8
    },
    {
      "rank": 34,
      "Collection": "Opepen Edition",
      "nft_contract_address": "0x6339e5e072086621540d0362c4e3cea0d643e114",
      "total_trade_count": 805
    },
    {
      "rank": 35,
      "Collection": "ETHEREALS",
      "nft_contract_address": "0xfc778be06c9a58f8f3e5e99216efbb28f750bc98",
      "total_trade_count": 9
    },
    {
      "rank": 36,
      "Collection": "RENGA",
      "nft_contract_address": "0x394e3d3044fc89fcdd966d3cb35ac0b32b0cda91",
      "total_trade_count": 789
    },
    {
      "rank": 37,
      "Collection": "KILLABEARS",
      "nft_contract_address": "0xc99c679c50033bbc5321eb88752e89a93e9e83c5",
      "total_trade_count": 82
    },
    {
      "rank": 38,
      "Collection": "UINTS",
      "nft_contract_address": "0x7c10c8816575e8fdfb11463dd3811cc794a1d407",
      "total_trade_count": 19
    },
    {
      "rank": 39,
      "Collection": "Terraforms",
      "nft_contract_address": "0x4e1f41613c9084fdb9e34e11fae9412427480e56",
      "total_trade_count": 217
    },
    {
      "rank": 41,
      "Collection": "0xVampire",
      "nft_contract_address": "0xc9e2c9718ff7d3129b9ac12168195507e4275cea",
      "total_trade_count": 2
    }
  ]
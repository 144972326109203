import React, { useState } from 'react'
import { normalizeLinks } from '../api/meta'
import {
    Profile
} from '../api/api'
import { explorerNFTURL, formatPrice, setWindowParam, getWindowParam, tweet } from '../utils'
import { VerifiedIcon } from './VerifiedIcon'

const dateToString = (d: string) => {
    if (!d) {
        return ''
    }

    const date = new Date(d)
    return date.toLocaleString('en-US', { month: '2-digit', year: '2-digit' }).replace(',', '/')
}

export const Table = (props: any) => {
    const data = props.data || []

    const drawIcons = (c: Profile) => {
        const links = { website: c.website, discord: c.discord, twitter: c.twitter }

        // @ts-ignore
        return Object.keys(links)
            // @ts-ignore
            .filter(k => links[k])
            .map(k => {
                // @ts-ignore
                const v1 = links[k]

                // @ts-ignore
                const v = normalizeLinks[k](v1)

                return (<a key={k} href={v} target="blank">
                    <img
                        title={v}
                        alt={v}
                        style={{ width: 20, display: 'inline', marginRight: 10 }}
                        src={`${k}.svg`}
                    />
                </a >)
            })
    }


    return (
        <div className="profiles-grid">
            <div>

                <strong style={{ textAlign: 'left' }}>Rank</strong>
                <strong></strong>
                <strong style={{ textAlign: 'left' }}>Collection</strong>


                <strong style={{ textAlign: 'left' }}>Owners</strong>
                <strong style={{ textAlign: 'left' }}>Created</strong>

                <strong style={{ textAlign: 'left' }}>Floor Price</strong>
                <strong style={{ textAlign: 'left' }}>OpenSea</strong>
                <strong style={{ textAlign: 'right' }}>Links</strong>

            </div>
            {data.map((p: Profile) => {
                const floorPrice = formatPrice(p.floorPrice)

                return (
                    <div
                        key={p.rank}
                        style={{ minHeight: 30 }}
                    >

                        <strong style={{ textAlign: 'left' }}>
                            {p.rank}</strong>
                        <span>
                            <img
                                style={{ width: 30 }}

                                src={p.img} />
                        </span>


                        <span style={{ textAlign: 'left' }}>
                            <a href={explorerNFTURL(p.address)} target="_blank" rel="noopener noreferrer">{p.name
                                ? <span>{p.name}<span style={{ fontSize: 10 }}>{/*p.symbol*/}
                                </span></span>
                                : p.address.substring(0, 20) + '...'}
                            </a>
                        </span>

                        <span style={{ textAlign: 'left' }}>{p.holders}</span>

                        <span style={{ textAlign: 'left' }}>{dateToString(p.createdAt)}</span>

                        <span style={{ textAlign: 'left' }}>
                            {floorPrice && <>
                                {floorPrice}
                                &nbsp;ETH</>
                            }
                        </span>
                        <span>
                            {p.isVerified === true && <VerifiedIcon />}
                        </span>

                        <span style={{ textAlign: 'right' }}>
                            {drawIcons(p)}
                        </span>

                    </div>
                )
            })}
            {data.length === 0 && <div>No results</div>}
        </div>

    )
}

import React, { useState } from 'react'
import { Chart } from './Chart';
import { tradeCountChart } from '../api/tradeCountChart'
import { totalVolumeChart } from '../api/totalVolumeChart'

export const Charts = (props: any) => {
    const [showTooltip, setShowTooltip] = useState(false);

    console.log({ tradeCountChart })
    return (
        <>
            <div style={{ width: '800px', height: '300px' }}>
                <Chart data={tradeCountChart} name={'Trade Count'} />
            </div>
            <div style={{ width: '800px', height: '300px' }}>
                <Chart data={totalVolumeChart} name={'Total Volume'} />
            </div>
        </>
    )
}

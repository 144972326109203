import { useEffect, useState } from 'react'
import { getStrategyData } from '../api/api';
import { throttle } from '../utils'

const throttled = throttle(500)

export const Search = (props: any) => {
    const strategy = props.strategy
    const cb = props.onSearch
    const showSuggestions = props.disableSuggestions || true
    const placeholder = props.placeholder || 'Enter NFT name of contract address'
    const [search, setSearch] = useState(props.initialValue || '')
    const [suggestions, setSuggestions] = useState([] as any)

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (search.length < 2) {
                setSuggestions([])
                return
            }

            const results = await getStrategyData(1, search, strategy.id)
            setSuggestions(results.map(r => r.name))
        }
        // fetchSuggestions()
    }, [search])

    const handleSuggestionClick = (suggestion: any) => {
        cb(suggestion)
        setSearch(suggestion)
        setSuggestions([])
    }

    return (
        <div className="search">
            <input
                type="text"
                name="handle"
                placeholder={placeholder}
                value={search}
                onChange={(e) => {
                    const v = e.target.value.toLowerCase()
                    setSearch(v)
                    throttled(() => cb(v))
                }}
            />
            {false && suggestions.length > 0 && (
                <div className="suggestions">
                    {suggestions.map((s: any) => (
                        <div className="suggestions-li" key={s} onClick={() => handleSuggestionClick(s)}>
                            {s}
                        </div>
                    ))}
                </div>
            )}

            {/*<button
                className="btn"
                onClick={() => {
                    cb(search)
                    setSuggestions([])
                }}
                type="submit">
                Search
            </button>*/}

            {(
                <button
                    className="btn"
                    type="button"
                    onClick={() => {
                        cb('')
                        setSearch('')
                    }}
                >
                    Clear
                </button>
            )}

        </div>
    )
}

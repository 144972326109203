import { useEffect, useState, useCallback } from 'react'
import {
	getStrategyData,
	PER_PAGE,
	getStrategyCount,
	Profile
} from '../api/api'

import Pagination from './Pagination'
import { explorerNFTURL, formatPrice, setWindowParam, getWindowParam, tweet } from '../utils'
import { normalizeLinks } from '../api/meta'
import { Search } from './Search'
import { Tooltip } from './Tooltip'
import {Table} from './Table'


export const loader = async (page: number, search: string, strategyId: string) => {
	const [results, count] = await Promise.all([
		getStrategyData(page, search, strategyId),
		getStrategyCount(search, strategyId),
	])

	const data = {
		results: results || [],
		page,
		count
	}

	return data
}

const getInitialPage = () => {
	return +getWindowParam('page') || 1
}


export default function List(props: any) {
	const strategies = props.strategies
	
	const initialStrategyId = getWindowParam('strategy')
	const initialStrategy = initialStrategyId
		? strategies.find((s: any) => s.id === initialStrategyId) || strategies[0]
		: strategies[0]

	const [strategy, setStrategy] = useState(initialStrategy)
	const strategyId = strategy.id

	const setStrategyCB = (strategy: any) => {
		setWindowParam('strategy', strategy.id)
		setStrategy(strategy)
	}

	const [data, setData] = useState({
		results: [] as Profile[],
		page: getInitialPage(),
		count: 0
	})

	const [page, setPage] = useState(getInitialPage())
	const [search, setSearch] = useState(getWindowParam('search') || '')

	const filterData = useCallback((s: string) => {
		setWindowParam('page', '1')
		setWindowParam('search', s)
		setSearch(s)
		setPage(1)
	}, [])


	useEffect(() => {
		const run = async () => {
			const d = await loader(page, search, strategyId)
			setData(d)
		}

		run()
	}, [page, strategyId, search])




	return (
		<main>

			<div className="logo-container">
				<a href="https://karma3labs.com/" target="_blank">
					<img
						width="180px"
						className="logo"
						src="/logo.svg"
						draggable="false"
						alt="Karma3Labs Logo"
					/>
				</a>
			</div>

			<div className="container">
				<header>
					<div className="title">
						<h1>People’s Choice NFT Rankings</h1>
						<p>
							<small>
								Open, Verifiable and Social attestation based NFT rankings&nbsp;
								<a style={{ borderBottom: '1px solid white' }}
									target="_blank" href="https://karma3-labs.gitbook.io/karma3labs/eigentrust/intuition">powered by EigenTrust</a>.&nbsp;
								<a style={{ borderBottom: '1px solid white' }} href="https://mirror.xyz/0x6BE454113A58861A09Fc2A57940a344357B3859a/6jyYtlnINF7E389vM4dJ0L0nWSWjxlY6PD1aUz1cmZA" target="_blank">&nbsp;Learn More.</a>
							</small>
						</p>
						<br />
						{<a style={{ borderBottom: '1px solid white' }}
							target="_blank" href="https://www.notion.so/karma3labs/NFT-Reputation-EigenTrust-Scoring-public-6ec9ec4529854a0cabb6e1cb8fefa8cf">
							<small>Ranking Strategies</small></a>}
					</div>

					<div className="strategies">

						{strategies.map((s: any) => {

							return (
								<Tooltip text={s.text}>
									<button
										style={{ width: 400, whiteSpace: 'break-spaces' }}
										key={s.id}
										className={s.id === strategy.id ? 'active btn' : 'btn'}
										onClick={() => setStrategyCB(s)}
										type="submit">
										{s.name}
									</button></Tooltip>
							)
						})}
						<Tooltip text={''}>
							<button
								style={{ width: 400, whiteSpace: 'break-spaces' }}
								key={'soon'}
								className={'btn'}
								onClick={() => { 
									window.location.pathname = '/personal'
								}}
								type="submit">
								Recommendations "For You" based on consensus among collectors like you
							</button>

						</Tooltip>

					</div>
					<Search onSearch={filterData} initialValue={search} strategy={strategy} />
					<a style={{ textDecoration: 'underline' }} href="https://twitter.com/karma3labs" target="_blank">
					<button className="twitter"
						style={{ marginTop: 30 }}
						/*onClick={() => {}/*tweet('Check out the Open NFT Rankings here:')*/
					>
						<i></i>&nbsp;Twitter
					</button>
					</a>

				</header>
				<div className="scroll" style={{ marginTop: 10 }}>
					<div className="profiles-container">
						<div>
							<Table data={data.results} />

							</div>
						<Pagination
							numberOfPages={Math.ceil(data.count / PER_PAGE)}
							currentPage={data.page}
							cb={p => setPage(p)}
						/>
					</div>
				</div>
			</div>
		</main>
	)
}

export function ErrorBoundary({ error }: { error: Error }) {
	return (
		<main>
			<div className="container">
				<h1>Error</h1>
				<p>{error.message}</p>
				<p>The stack trace is:</p>
				<pre>{error.stack}</pre>
			</div>
		</main>
	)
}

import React, { useState, useEffect } from 'react'
import { getMeta } from '../api/api'

import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    Title
} from 'chart.js';
import { Bubble, Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker'
import { truncate } from 'fs';

ChartJS.register(BarElement, LinearScale, PointElement, Tooltip, Legend, CategoryScale, Title)


const predefinedPalette = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#7cb4b9', '#f7a35c', '#4d4d4d', '#ff8f00', '#3b3eac', '#74d600', '#b30900', '#6a76fb', '#008080', '#fcb7d2'];


export const Chart = (props: any) => {
    const chartData = props.data
    const chartName = props.name || 'Value'

    const [fullData, setFullData] = useState([])

    const options = {
        scales: {
            y: {
                label: {
                    display: true
                },
                beginAtZero: true,
                //border:{line: true}, // for the grid lines
                grid: {
                    color: '#333', // for the grid lines
                    tickColor: '#000', // for the tick mark
                    tickBorderDash: [2, 3], // also for the tick, if long enough
                    tickLength: 10, // just to see the dotted line
                    tickWidth: 2,
                    offset: true,
                    drawTicks: true, // true is default 
                    drawOnChartArea: true // true is default 
                },
            },
            x: {
                // border:{line: true}, // for the grid lines
                grid: {
                    color: '#333', // for the grid lines
                    tickColor: '#000', // for the tick mark
                    tickBorderDash: [2, 3], // also for the tick, if long enough
                    tickLength: 10, // just to see the dotted line
                    tickWidth: 2,
                    offset: true,
                    drawTicks: true, // true is default 
                    drawOnChartArea: true // true is default 
                },
                label: {
                    display: true
                },
            }
        },
        plugins: {
            legend: {
                labels: {
                    // usePointStyle: true,
                },
                display: false,
            },
            title: {
                display: true,
                text: chartName,
              }
        }
    };

    useEffect(() => {
        const run = async () => {
            const d = await getMeta(chartData.map((c: any) => c.nft_contract_address))

            const data = d
                .filter((e: any) => e.img)
                .map((d: any, i: number) => {
                    const values = chartData.find((c: any) => c.nft_contract_address === d.address)

                    return {
                        label:  d.name + `, ${chartName}: ${values.total_trade_count || values.total_eth_volume}, Rank: ${values.rank} `,
                        data: [{
                            
                            x: values.rank,
                            y: values.total_trade_count || values.total_eth_volume,
                            r: 1,
                        }],
                        // pointStyle: image,
                        // pointRadius: 100,
                        borderColor: predefinedPalette[i],
                        backgroundColor: predefinedPalette[i],
                    }
                })

            setFullData(data)
        }

        run()
    }, [])

    if (fullData.length === 0) {
        return null
    }

    const d = {
        datasets: fullData,
       labels: ['']
    }

    return <Bar options={options} data={d} />
}

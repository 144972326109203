import './App.css';
import List from './components/List'
import PersonalList from './components/PersonalList'
import { useEffect, useState } from 'react'
import { getStrategies } from './api/api';
import { SearchAutocomplete } from './components/SearchAutocomplete'
import { getWindowParam, setWindowParam } from './utils';
import { useCallback } from 'react'
import { Charts } from './components/Charts'

const isRankingPage = () => window.location.pathname.indexOf('/rankings') !== -1

function App() {
  const [strategies, setStrategies] = useState([])
  const [isRanking, setIsRanking] = useState(isRankingPage)

  const isPersonal = window.location.pathname.indexOf('/personal') !== -1

  useEffect(() => {
    const run = async () => {
      // @ts-ignore
      const s = await getStrategies()

      setStrategies(s)
    }

    run()
  }, [])

  const onSearchAutocomplete = useCallback((s: string) => {
    window.history.pushState({}, '', '/rankings')
    setWindowParam('page', '1')
    setWindowParam('search', s)
    setIsRanking(true)
  }, [])

  console.log({isPersonal})

  if (isPersonal) {
    return (<div className="App">
      <PersonalList strategies={strategies} />
    </div>)
  }

  if (!strategies.length) {
    return <></>
  }

  if (window.location.pathname.indexOf('/chart') !== -1) {
    return <Charts></Charts>
  }


  if (!isRanking && false) {
    return (
      <div className="App">
        <SearchAutocomplete
          setMode={setIsRanking}
          strategy={strategies[0]} onSearch={onSearchAutocomplete} />
      </div>
    )
  }

  return (
    <div className="App">
      <List strategies={strategies} />
    </div>
  );
}

export default App;